import React from "react"
import { Formik, Form, Field } from "formik"
import axios from "axios"
import classNames from "classnames"
import * as Yup from "yup"
import PhoneInputField from "./PhoneInputField"

const ContactSchema = Yup.object().shape({
  message: Yup.string()
    .max(500, "Не более 500 символов!")
    .required("Обязательное поле"),
})

const FormComplaints = () => (
  <Formik
    initialValues={{
      message: "",
    }}
    validationSchema={ContactSchema}
    onSubmit={(values, actions) => {
      const msg = encodeURIComponent(
        "<b>---------Сообщение---------\n\n</b>" + `${values.message}\n\n`
      )
      const url = `https://api.telegram.org/bot1539411093:AAGxi2gynXGTVDITwP5NXJPlo23q0gUPceg/sendMessage?chat_id=1347204802&parse_mode=HTML&text=${msg}`
      axios
        .get(url)
        .then(function (response) {
          actions.setStatus({ msg: "Сообщение успешно отправлено" })
          setTimeout(actions.resetForm, 3000)
        })
        .catch(function (error) {
          console.log(error)
        })
    }}
  >
    {({ errors, touched, status }) => {
      return (
        <Form className="container has-family-pt-sans">
          <div className="field">
            <div className="control">
              <Field
                component="textarea"
                name="message"
                id="message"
                className={classNames("textarea")}
              />
              {errors.message && touched.message ? (
                <p className="is-size-7 has-text-primary has-text-weight-semibold">
                  {errors.message}
                </p>
              ) : null}
            </div>
          </div>

          {status && status.msg && (
            <p className="has-text-weight-900 has-text-success mb-1 has-text-centered">
              Ваша заявка принята
            </p>
          )}
          <div className="control has-text-centered-mobile">
            <button
              type="submit"
              class="button hvr-bounce-to-top is-size-6-mobile has-text-weight-bold is-medium is-success is-outlined mb-2"
            >
              <strong>Отправить</strong>
            </button>
          </div>
        </Form>
      )
    }}
  </Formik>
)

export default FormComplaints
