import React from "react"
import Sticky from "react-sticky-el"
import SEO from "../components/SEO/index"
import NavBar from "../components/navbar"
import FormComplaints from "../components/form-complaints"

const ComplaintsPage = () => {
  return (
    <>
      <SEO title="UZMBC" description="Multi Business Company - Жалобы" />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <div className="section container">
        <h2 className="is-size-2 is-uppercase is-size-6-mobile has-text-weight-light mb-2">
          Документ
        </h2>
        <div className="mb-2">
          <a
            href="complaints.docx"
            target="_blank"
            className="button is-uppercase has-family-pt-sans hvr-bounce-to-top has-text-weight-bold is-primary is-outlined has-text-black"
          >
            Претензии
          </a>
        </div>

        <h2 className="is-size-2 is-uppercase is-size-6-mobile has-text-weight-light mb-2">
          Ваше обращение, жалоба и предложение
        </h2>
        <FormComplaints />
      </div>
    </>
  )
}

export default ComplaintsPage
